// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-booth-js": () => import("./../../src/pages/booth.js" /* webpackChunkName: "component---src-pages-booth-js" */),
  "component---src-pages-dashboard-js": () => import("./../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-graphs-js": () => import("./../../src/pages/graphs.js" /* webpackChunkName: "component---src-pages-graphs-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

